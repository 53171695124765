import { calculateBreakpoints, waitForTime } from 'platform/utils'

import { Froala, initFroalaEditor, loadFroala } from './froala'

import { LargeAttachmentsList, NullAttachmentsList } from './attachmentslist'
import { registerInternalLinkCommand } from './commands'
import { uploadImageAsUpload, uploaderFactory } from './fine-uploader'
import InternalLinkWidget from './internal-link-widget'
import { setupMentions } from './mentions'
import SnippetWidget from './snippet-widget'
import SelfServiceWidget from './self-service-widget'
import { getToolbar } from './toolbar'
import { ALLOWED_IMAGE_EXTENSIONS, getFilesFromEvent, isAllowedImageMime } from './utils'

const FROALA_KEY = '8F4A3C3E3B5B4D-13C3B2E2F2E3B1C6C7D2E2qAJf1Na1ZWPXDf1FQa1E=='

export interface FroalaConfig {
  allowMentions?: boolean
  allowedAttributes?: any[]
  allowedTags?: any[]
  enterType?: string
  saveUrl?: string
  slimToolbarType?: string
  snippetsUrl?: string
  toolbarType?: string
  uploadsUrl?: string
}

export default class KundoFroala2Editor {
  attachmentsList: LargeAttachmentsList | NullAttachmentsList
  editor: JQuery<HTMLTextAreaElement>
  froala: Froala

  isInitialized: boolean = false
  isMailContext: boolean
  isAIEnabled: boolean = false
  isConnectedToSmartReplyChannel: boolean = false
  hasSmartReplyChannels: boolean = false
  hasInboxEditPermissions: boolean = false
  allowLargeUploads: boolean
  isMobile: boolean
  uploadsUrl?: string
  toolbar: ReturnType<typeof getToolbar>
  slimToolbar: ReturnType<typeof getToolbar>

  autosaveStatus: JQuery<HTMLElement>
  texteditorDiv: JQuery<HTMLElement>

  internalLinkWidget: InternalLinkWidget
  snippetWidget: SnippetWidget
  selfServiceWidget: SelfServiceWidget

  usesFroala2: boolean

  config: FroalaConfig

  // List of pending
  private __pendingEventHandlers?: { event: string; callback: Function; opts?: any }[]

  constructor(
    texteditorDiv: JQuery<HTMLElement>,
    attachmentsList?: undefined | LargeAttachmentsList,
    isMailContext: boolean = false,
    allowLargeUploads: boolean = false,
  ) {
    if (texteditorDiv.length !== 1) {
      throw new Error('too many divs or too few')
    }
    if ($('html').hasClass('lt-ie10')) {
      return
    }

    this.texteditorDiv = texteditorDiv
    // Save a reference to our *own* class on the HTML element itself, for later access
    // @ts-expect-error
    this.texteditorDiv[0].kundoFroala = this

    this.attachmentsList = attachmentsList || new NullAttachmentsList()
    this.editor = this.texteditorDiv.find('textarea')
    this.autosaveStatus = this.texteditorDiv.find('.fn-autosave-status')
    this.isMailContext = isMailContext
    this.allowLargeUploads = allowLargeUploads
    this.isMobile = calculateBreakpoints().mobile

    this.config = JSON.parse(this.texteditorDiv.find('[data-fn-texteditor-config]').text() || '{}')

    this.uploadsUrl = this.config.uploadsUrl

    const { saveUrl } = this.config

    const internalLinkElm = this.texteditorDiv.find('.fn-internal-link-box')

    this.isAIEnabled = this.editor.parents('form:first').data('smart-replies-enabled') == true
    this.isConnectedToSmartReplyChannel =
      this.editor.parents('form:first').data('connected-to-smart-reply-channel') == true
    this.hasSmartReplyChannels =
      this.editor.parents('form:first').data('has-smart-reply-channels') == true
    this.hasInboxEditPermissions =
      this.editor.parents('form:first').data('has-inbox-edit-permissions') == true

    const toolbar = getToolbar(this.config.toolbarType!, {
      // Only show the InternalLinkWidget button when the widget is provided
      includeInternalLinks: !!internalLinkElm.length,
      includeAI: !!this.isAIEnabled,
    })

    this.toolbar = toolbar

    let editorClass = ''
    if (this.toolbar.buttons.length === 0) {
      editorClass = 'fr-hide-toolbar'
    }

    const htmlAllowedAttrs = (this.config.allowedAttributes || []).concat(['data-froala-role'])
    const htmlAllowedTags = this.config.allowedTags || ['div', 'br']
    let pasteDeniedAttrs = ['class', 'id', 'style']
    let pasteDeniedTags = ['span']
    if (toolbar.hasColor) {
      pasteDeniedAttrs = ['class', 'id']
      pasteDeniedTags = []
    }
    if (this.config.allowMentions) {
      pasteDeniedAttrs = ['id']
      pasteDeniedTags = []
    }

    const options = {
      dragInline: true,
      editorClass,
      events: { initialized: () => this.onInitialized() },
      heightMax: this.editor.data('maxHeight'),
      htmlAllowedAttrs,
      htmlAllowedTags,
      imageAllowedTypes: ALLOWED_IMAGE_EXTENSIONS,
      imageDefaultWidth: 0,
      // prettier-ignore
      imageEditButtons: ['imageAlt', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '|', 'imageRemove'],
      imageInsertButtons: ['imageRemove'],
      imagePaste: true,
      imageResize: true,
      imageUploadURL: '_/froala_shouldnt_upload', // Make sure froala doesn't send anything to it's own servers,
      key: FROALA_KEY,
      language: 'kundo-lang',
      linkAutoPrefix: 'http://',
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      linkInsertButtons: ['linkBack'],
      listAdvancedTypes: false,
      paragraphFormat: {
        H2: TRANSLATIONS.paragraphFormat.h2,
        H3: TRANSLATIONS.paragraphFormat.h3,
        N: TRANSLATIONS.paragraphFormat.n,
      },
      paragraphFormatSelection: true,
      paragraphStyles: {
        'direction-rtl': TRANSLATIONS.paragraphStyles.directionRtl,
        lead: TRANSLATIONS.paragraphStyles.lead,
      },
      pasteAllowedStyleProps: toolbar.hasColor ? ['color', 'background-color'] : [],
      pasteDeniedAttrs: pasteDeniedAttrs,
      pasteDeniedTags: pasteDeniedTags,
      // @ts-expect-error
      placeholderText: this.editor.placeholder || '',
      requestWithCORS: false,
      saveInterval: saveUrl ? 500 : 0,
      saveURL: saveUrl,
      shortcutsEnabled: ['bold', 'italic', 'undo', 'redo', 'insertLink', 'snippets'],
      spellcheck: true,
      tableEditButtons: ['tableHeader', '|', 'tableRows', 'tableColumns', '|', 'tableRemove'],
      tableResizer: false,
      theme: 'froala2-kundo',
      toolbarBottom: toolbar.bottom,
      toolbarButtons: toolbar.buttons,
      toolbarButtonsMD: toolbar.buttons,
      toolbarButtonsSM: toolbar.buttons,
      toolbarButtonsXS: toolbar.buttons,
      toolbarSticky: !this.isMobile,
      toolbarStickyOffset: $('.modal-heading').length > 0 ? $('.modal-heading').outerHeight() : 0,
      videoAllowedTypes: [],
      videoEditButtons: ['videoRemove'],
      videoInsertButtons: ['videoByURL'],
      videoMove: false,
      videoResize: false,
      videoUploadURL: '',
    }

    loadFroala((FroalaEditor) => {
      registerInternalLinkCommand(FroalaEditor, internalLinkElm.find('h3').text())

      const enterOptions = {
        br: FroalaEditor.ENTER_BR,
        div: FroalaEditor.ENTER_DIV,
        p: FroalaEditor.ENTER_P,
      }
      const enter = enterOptions[this.config.enterType || 'br']

      initFroalaEditor(this, { ...options, enter })
    })

    this.texteditorDiv.removeClass('text-editor--pre-init')
  }

  on(event: string, callback: Function, opts?: any) {
    if (this.isInitialized) {
      this.froala.events.on(event, callback, opts)
    } else {
      // Keep the callback registration in memory until the Froala is initialized,
      // so we can call froala.events.on when it is.
      this.__pendingEventHandlers = this.__pendingEventHandlers || []
      this.__pendingEventHandlers.push({ callback, event, opts })
    }
  }

  onInitialized() {
    this.isInitialized = true

    // Make sure the froala instance has a reference to this object,
    // since it is needed for file uploads, reply snippets etc
    this.froala.kundoFroala = this

    this.setUpToolbar()
    this.setUpAutoSave()
    this.setUpSnippets()
    this.setUpImageUploads()
    this.setUpVideo()
    this.setUpPaste()
    this.setUpInternalLinks(this.texteditorDiv.find('.fn-internal-link-box'))
    this.setUpSelfService()
    this.setUpLinkInsertion()
    this.setUpTableInsertion()
    this.setUpFocus()
    this.setUpMentions()
    this.setUpEvents()
    this.removeToolbarButtonsTabindex()
    this.editorPlaceholder()

    this.froala.events.trigger('kundoFroalaInitialized')
  }

  setUpEvents() {
    const callbacks = this.__pendingEventHandlers || []
    for (const { callback, event, opts } of callbacks) {
      this.froala.events.on(event, callback, opts)
    }
    delete this.__pendingEventHandlers
  }

  setUpLinkInsertion() {
    // Create link without showing popup if a link is marked
    this.on('commands.before', (cmd: string) => {
      if (cmd !== 'insertLink') {
        return
      }
      const selection = this.froala.selection.text()
      if (KUNDO.utils.isUrl(selection.trim())) {
        this.froala.link.insert(selection)
        return false
      }
    })

    this.on('commands.after', async (cmd: string) => {
      if (cmd === 'insertLink') {
        await waitForTime(20)
        this.texteditorDiv.find('.fr-popup input:first').trigger('focus')
      }
    })
  }

  setUpTableInsertion() {
    this.on('table.inserted', (table: Element) => {
      if (this.texteditorDiv.data('context-type') === 'inbox') {
        table.setAttribute('border', '1')
        table.setAttribute('data-kundo-style', 'table')
      }
    })
  }

  setUpFocus() {
    const { texteditorDiv } = this
    const cls = 'text-editor--has-focus'
    this.on('focus', () => texteditorDiv.addClass(cls))
    this.on('blur', () => texteditorDiv.removeClass(cls))
  }

  setUpToolbar() {
    const elm = this.texteditorDiv
    elm.find('div.fr-wrapper').attr('data-hj-suppress', '')

    if (this.toolbar.inserted) {
      const elm = this.texteditorDiv
      elm.find('div.fr-wrapper').append(elm.find('div.fr-toolbar'))
    }

    const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)

    // Froala editor doesn't support toolbarBottom https://github.com/froala/wysiwyg-editor/issues/1281
    // Fix to support toolbarBottom on ios, change classes
    if (isIOS && this.toolbar.bottom) {
      this.texteditorDiv.find('.fr-top').each((_, item) => {
        item.classList.remove('fr-top')
        item.classList.add('fr-bottom')
      })
    }

    // Make sure the builtin icons get our close icon
    $('.fa-times').addClass('f-icon-cross')
  }

  removeToolbarButtonsTabindex() {
    this.toolbar.buttons.forEach((button) => {
      const $button = this.texteditorDiv.find(`[data-cmd="${button}"]`)
      $button.removeAttr('tabindex')
    })
  }

  editorPlaceholder() {
    this.texteditorDiv.each((_, item) => {
      const $placeholder = this.texteditorDiv.find('.fr-placeholder').text()
      const $editor = this.texteditorDiv.find('.fr-element')
      $editor.attr('aria-placeholder', $placeholder)
      $editor.attr('aria-label', $placeholder)
      $editor.attr('role', 'textbox')
    })
  }

  setUpSnippets() {
    const url = this.config.snippetsUrl
    if (!url) {
      return
    }
    this.snippetWidget = new SnippetWidget({
      url,
      parent: this.texteditorDiv[0],
      onselect: ({ text, attachments }) => {
        this.froala.selection.restore()

        attachments
          .filter((file) => !this.attachmentsList.has_file(file))
          .forEach((file) => this.attachmentsList.add_file(file))

        this.froala.html.insert(text)
      },
      onclose: (focus: boolean) => {
        if (focus) {
          this.froala.events.focus()
        }
      },
    })
  }

  setUpInternalLinks(elm: JQuery<HTMLElement>) {
    if (!elm.length) {
      return
    }
    this.internalLinkWidget = new InternalLinkWidget({
      box: elm,
      onselect: (_, text, link) => {
        this.froala.link.insert(link, text)
        this.froala.selection.restore()
      },
      onclose: (_, focus) => {
        if (focus) {
          this.froala.events.focus()
        }
      },
    })
  }

  setUpSelfService() {
    if (!this.isAIEnabled) {
      return
    }
    const aiToolbarButton = document.getElementById('selfService-1')
    if (!aiToolbarButton) {
      return
    }
    this.selfServiceWidget = new SelfServiceWidget({
      parent: this.texteditorDiv[0],
      insertLinkFunction: this.froala.link.insert,
      insertHTMLFunction: (html) => {
        this.froala.events.focus()
        this.froala.selection.restore()
        this.froala.html.insert(html)
      },
      froalaEditOnFunction: this.froala.edit.on,
      froalaEditOffFunction: this.froala.edit.off,
      onselect: () => {
        this.froala.selection.restore()
      },
      onclose: (focus: boolean) => {
        if (focus) {
          this.froala.events.focus()
        }
      },
      aiToolbarButton: aiToolbarButton,
      isConnectedToSmartReplyChannel: this.isConnectedToSmartReplyChannel,
      hasSmartReplyChannels: this.hasSmartReplyChannels,
      hasInboxEditPermissions: this.hasInboxEditPermissions,
    })
  }

  setUpPaste() {
    /*
     * Add br tag at the end of removed block elements
     **/
    this.on('paste.beforeCleanup', (content: string) => {
      if (!content) {
        return content
      }
      const addLineBreaksBeforeCloseTag = (htmlString, tagNamesArray) => {
        tagNamesArray.forEach((tagName) => {
          const re = RegExp('</' + tagName + '>', 'gi')
          htmlString = htmlString.replace(re, '<br></' + tagName + '>')
        })
        return htmlString
      }
      const allowedEditorTags = this.config.allowedTags || []
      const enterType = this.config.enterType

      // prettier-ignore
      const defaultBlockTagsInNeedOfLineBreakOnPaste = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'th', 'td', 'dt', 'dd']
      // prettier-ignore
      const tags = defaultBlockTagsInNeedOfLineBreakOnPaste
        .filter((element) => allowedEditorTags.indexOf(element) < 0)

      // even if p elements are allowed (data-allowed-tags),
      // those might be converted based on Froala's enter propery
      if (enterType !== 'p' && tags.indexOf('p') === -1) {
        tags.push('p')
      }

      return addLineBreaksBeforeCloseTag(content, tags)
    })

    this.on('paste.afterCleanup', (content: string) => {
      return content.replace(/&nbsp;/g, ' ')
    })
  }

  setUpVideo() {
    const getVideoInput = (editor) => editor.popups.get('video.insert').find('input')
    const removeErrorMsg = (elm) => elm.parent().find('.input__error-feedback').remove()
    this.on('video.linkError', function (e, editor, link) {
      const $input = getVideoInput(editor)
      if ($input) {
        removeErrorMsg($input)
        const errorFeedbackId = $input.attr('id') + '-error'
        const $errorMsg = $('<div>', { id: errorFeedbackId, class: 'input__error-feedback' })
          .attr('aria-live', 'polite')
          .html(
            'Adressen pekar inte till en videotjänst vi stödjer. De tjänster som stöds är: SVT, Vimeo och YouTube.<br>Kontakta <a href="mailto:support@kundo.se">support@kundo.se</a> om du saknar en tjänst.',
          )
        $input.parent().append($errorMsg)
        $input.attr({ 'aria-invalid': 'true', 'aria-labelledby': errorFeedbackId })
      }
    })

    this.on('video.inserted', function (e, editor, $video) {
      const $input = getVideoInput(editor)
      if ($input) {
        $input.removeAttr('aria-invalid aria-labelledby')
        removeErrorMsg($input)
      }
    })
  }

  setUpAutoSave() {
    if (!this.config.saveUrl) {
      return
    }
    const IGNORED_FIELD_NAMES = [
      'csrfmiddlewaretoken', // Is sent via a Cookie instead
      'cmt-text', // Same contents as the "body" parameter that Froala sends
    ]
    const fieldIgnored = (field) => IGNORED_FIELD_NAMES.indexOf(field.name) !== -1

    // Trigger autosave when non-froala fields change.
    const editor_fields = this.editor.parents('form:first').find('input, select, textarea')
    editor_fields
      .filter((_, field) => !fieldIgnored(field))
      .each((_, field) => {
        $(field).on('input change', () => this.setDirty())
      })

    this.on('save.before', () => {
      // If there's a loader in the editor cancel the save
      if (this.froala.html.get().search(/smart-replies-froala-content--loader/g, '') != -1) {
        return false
      }

      this.setAutosaveStatus(TRANSLATIONS.editor_before_save_msg)

      const form = this.editor.parents('form:first')
      const formFields = form
        .serializeArray()
        .filter((field) => field.value) // Remove empty fields
        .filter((field) => !fieldIgnored(field)) // Remove ignored fields

      this.froala.opts.saveParams = {
        meta: JSON.stringify(formFields),
      }
    })

    this.on('save.error', () => {
      this.setAutosaveStatus(TRANSLATIONS.editor_save_error_msg)
    })

    this.on('save.after', () => {
      this.setAutosaveStatus(TRANSLATIONS.editor_after_save_msg, { delay: 1000, autoHide: true })
    })

    this.setDirty = this.setDirty.bind(this)
  }

  setUpImageUploads() {
    const onBeforePaste = (e: Event) => {
      let file: null | File = null
      const files = getFilesFromEvent(e)
      for (let i = 0, len = files.length; i < len; i++) {
        file = files[i]
        if (file && isAllowedImageMime(file.type)) {
          this.insertImage(file)
        } else {
          this.attachFile(file)
        }
      }
      // Cancel event if any file was added, else fall through to Froalas drag handling
      if (file) {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    }

    this.on('drop paste.before', onBeforePaste, { capture: true })

    this.on('image.loaded', (img) => {
      this.froala.image.setSize(`${img.width()}px`, 'auto')
    })

    // If `onBeforePaste` doesn't find any images, Froalas default event will be used
    this.on('image.beforePasteUpload', (img) => {
      // Remove pasted image from the editor, and instead insert
      // the one from the insertImage function
      this.froala.selection.setBefore(img)
      $(img).remove()
    })

    this.on('image.beforeUpload', (images) => {
      if (images) {
        images.forEach((imageBlob) => {
          this.insertImage(imageBlob)
        })
      }

      return false
    })

    this.on('image.removed', (img) => {
      this.attachmentsList.remove_file_by_url(img.attr('src'))
    })
  }

  setUpMentions() {
    if (!this.config.allowMentions) return
    if (!window.EDITORSEARCHURL) return

    const tribute = setupMentions(this.froala, this.texteditorDiv[0])

    fetch(window.EDITORSEARCHURL)
      .then((resp) => resp.json())
      .then((data) => {
        tribute.append(0, data['users'])
      })
  }

  setAutosaveStatus(message: string, opts?: { delay: number; autoHide?: boolean }) {
    const delay = opts?.delay || 1000
    setTimeout(() => this.autosaveStatus.html(message).show(), delay)
    if (opts?.autoHide) {
      setTimeout(() => this.autosaveStatus.html('').hide(), delay + 1000)
    }
  }

  /**
   * Returns the underlying [contenteditable] element of the editor, for low-level access.
   */
  getContentEditable(): HTMLElement {
    return this.froala.$el[0]
  }

  insertImage(files) {
    if (!this.uploadsUrl) {
      return
    }
    uploadImageAsUpload(
      this,
      files,
      ALLOWED_IMAGE_EXTENSIONS,
      this.isMailContext,
      this.allowLargeUploads,
    )
  }

  attachFile(files) {
    if (!this.uploadsUrl) {
      return
    }
    uploaderFactory(
      this.uploadsUrl,
      this.attachmentsList,
      this.isMailContext,
      this.allowLargeUploads,
    ).addFiles(files)
  }

  focus() {
    this.froala.events.focus()
  }

  getHTML(): string {
    return this.froala.html.get()
  }

  setHTML(html: string | null) {
    this.froala.html.set(html)
  }

  setDirty() {
    this.froala.save.reset()
    this.froala.save.force()
  }

  saveSelection() {
    this.froala.selection.save()
  }

  openSnippetWidget() {
    this.saveSelection()
    this.snippetWidget.open()
  }

  toggleSnippetWidget() {
    this.saveSelection()
    this.snippetWidget.toggle()
  }

  toggleInternalLinkWidget() {
    this.saveSelection()
    this.internalLinkWidget.toggle()
  }

  toggleSelfServiceWidget() {
    this.saveSelection()
    this.selfServiceWidget.toggle()
  }
}

export function getKundoFroalaInstance(elm: HTMLElement | JQuery): KundoFroala2Editor | null {
  let $elm = $(elm)
  let obj = $elm[0] as any
  if (obj && obj['kundoFroala']) {
    return obj['kundoFroala']
  }
  obj = $elm.parent('.fn-text-editor')[0]
  if (obj && obj['kundoFroala']) {
    return obj['kundoFroala']
  }
  obj = $elm.parent('[data-fn-text-editor]')[0]
  if (obj && obj['kundoFroala']) {
    return obj['kundoFroala']
  }
  return null
}
